<template>
  <main data-int="home" class="section is-paddingless">
    <hero/>
    <section class="home-container">
      <browse />
      <slot name="shelves">
        <shelf-section v-if="shelves && shelves.length === 1" :shelf-id="shelves[0].id" />
        <shelf-list v-else-if="shelves && shelves.length > 1" :list="shelves" />
      </slot>
      <slot name="recentlyViewed">
        <recently-viewed />
      </slot>
      <recently-added />
    </section>
  </main>
</template>

<script>
import http from '@/http';
import Hero from '@/components/common/Hero';
import Browse from './Browse';
import ShelfList from '../shelves/ShelfList';
import ShelfSection from '../shelves/ShelfSection';
import RecentlyAdded from './RecentlyAdded';
import RecentlyViewed from './RecentlyViewed';
import { onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: 'Home',
  components: {
    Browse,
    Hero,
    ShelfList,
    ShelfSection,
    RecentlyViewed,
    RecentlyAdded
  },
  emits: ["clearSearchInput"],
  setup (props, context) {
    const store = useStore()

    onMounted( async ()  => {
      await store.dispatch('search/clearSearchQuery')
    })
  },
  data() {
    return {
      shelves: null
    };
  },
  async created() {
    try {
      const {data} = await http.get('media-shelves');
      this.shelves = data;
    } catch (error) {
      console.log('error :>> ', error);
    }
    document.title = "Documoto"
  }
};
</script>

<style scoped>
.section {
  flex: 1;
  display: flex;
  flex-flow: column;
}
.home-container {
  flex: 1;
  overflow: auto;
}
</style>
