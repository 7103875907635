import { toRaw } from 'vue'
import http from '@/http'
import router from '@/router'
import i18n from '@/locales'
import { getRoute } from "@/router/getRoute"
import filters, { SEARCH_FILTERS_SET_SELECTED } from './filters'
import { isTrue } from '@/utils/boolean-check'

const SEARCH = 'SEARCH'
const SEARCH_LOADING = 'SEARCH_LOADING'
const SEARCH_UPDATE_OFFSET = 'SEARCH_UPDATE_OFFSET'
const SEARCH_HISTORY_UPDATE = 'SEARCH_HISTORY_UPDATE'
const SEARCH_UPDATE_LIMIT = 'SEARCH_UPDATE_LIMIT'
const SEARCH_UPDATE_SORT = 'SEARCH_UPDATE_SORT'
const SET_VIEW_MODE_KEY = 'SET_VIEW_MODE_KEY'
const CLEAR_SEARCH_TRACKING_ID = 'CLEAR_SEARCH_TRACKING_ID'
const SET_SEARCH_TRACKING_ID = 'SET_SEARCH_TRACKING_ID'
const SET_PREVIOUS_SEARCH_REQUEST = 'SET_PREVIOUS_SEARCH_REQUEST'
const SET_KEEP_OFFSET = 'SET_KEEP_OFFSET'
const SET_SUGGESTION_QUERY = 'SET_SUGGESTION_QUERY'

const state = {
  items: [],
  offset: 0,
  limit: 20,
  total: 0,
  sort: '',
  direction: '',
  isLoaded: false,
  viewModeKey: localStorage.viewModeKey || 'list',
  searchTrackingId: null,
  previousSearchRequest: null,
  isSearching: false,
  searchHistory: [],
  searchHistoryLoaded: false,
  keepOffset: false,
  suggestionQuery: null
}

const getters = {
  backToSearchRouteDestination: (state) => {
    const queryParams = Object.assign({}, state.filters.selected)
    queryParams.q = state.filters.query || undefined
    queryParams.exact = state.filters.exact

    return {
      name: 'Search',
      query: queryParams ? queryParams : null
    }
  },
  isListView(state) {
    return state.viewModeKey === 'list'
  },
  isWidget(state, getters, rootState, rootGetters) {
    return rootGetters['widgets/isWidget']
  },
  isWidgetListView(state, getters) {
    return getters.isWidget && getters.isListView
  },
  getSearchTrackingId(state) {
    return state.searchTrackingId
  },
  getSearchHistory(state, getters, rootState, rootGetters) {
    if (!state.searchHistoryLoaded) {
      const userId = rootGetters['user/getUserId']
      state.searchHistory = localStorage.getItem('searchHistory_' + userId)?.split("|*|") ?? []
      state.searchHistoryLoaded = true
      return state.searchHistory
    } else {
      return state.searchHistory
    }
  }
}

const actions = {
  async search ({ commit, state, getters, dispatch, rootGetters }, { q = '', exactMatch = 'false', callType = null }) {
    try {
      // Prevent poor component architecture from duplicating search calls
      const previousRequest = toRaw(state.previousSearchRequest)
      const isMatchingRequest = (previousRequest?.q === q)
        && (String(previousRequest?.exactMatch) === String(exactMatch))
        && (previousRequest?.callType === callType)
      if (isMatchingRequest && state.isSearching) return

      commit(SEARCH_LOADING)
      commit(SET_PREVIOUS_SEARCH_REQUEST, { q, exactMatch, callType })

      const limit = getters.isWidgetListView ? 100 : state.limit
      const params = {
        limit,
        offset: state.offset * limit,
      }
      // Only add callType if it's been passed in. Adding
      // callType to the request forces turbo to create
      // a search log entry in the db. We only add callType
      // on the offset 0 of the search call (the first page)
      // which returns the tracking id with search results.
      // We use this to populate searchTrackingId in state.
      if (callType) {
        params.callType = callType
      }
      if (q !== null && q !== undefined && q !== '') {
        // Type-safe boolean check; can handle both boolean and string
        params.q = isTrue(exactMatch) && (!q.startsWith('"')) ? `"${q}"` : q
        const userId = rootGetters['user/getUserId']
        const existingSearchHistory = localStorage.getItem('searchHistory_' + userId)?.split("|*|") ?? []
        const newSearchHistorySet = new Set([q].concat(existingSearchHistory))
        const newSearchHistoryArray = [...newSearchHistorySet].slice(0, 5)
        commit(SEARCH_HISTORY_UPDATE, newSearchHistoryArray)
        const newSearchHistoryStr = newSearchHistoryArray.join("|*|")
        localStorage.setItem('searchHistory_' + userId, newSearchHistoryStr)
      }

      if (state.sort) {
        params[`sort_${state.direction || 'asc'}`] = state.sort
      }
      params.entity_type = '-Chapter'

      const prefixes = []
      const suffixes = []
      Object.keys(state.filters.selected).forEach((key) => {
        const prefixIndex = key.indexOf('_prefix')
        const suffixIndex = key.indexOf('_suffix')
        if (prefixIndex > -1) {
          prefixes.push(state.filters.selected[key])
        } else if (suffixIndex > -1) {
          suffixes.push(state.filters.selected[key])
        } else {
          params[key] = state.filters.selected[key]
        }
      })

      if (prefixes.length > 0) {
        params.tag_range_prefix = [prefixes.toString()]
      }
      if (suffixes.length > 0) {
        params.tag_range_suffix = [suffixes.toString()]
      }

      const { headers, data } = await http.get('search', { params })
      const items = getters.isWidgetListView && !!params.offset
        ? state.items.concat(data.items)
        : data.items
      commit(SEARCH, { items, meta: headers, facets: data.facets, selected: state.filters.selected })

      if (!!data.searchLogId) {
        dispatch('setSearchTrackingId', data.searchLogId)
      }
      commit('browse/BROWSE_RESET', null, { root: true })
    } catch (err) {
      // na
    }
  },
  async searchWithReturn (context, { q = '', limit, offset, searchableType, includeAllMedia, callType = null }) {
    let result = []
    try {
      const params = {
        callType: callType,
        limit: limit || 10,
        offset: offset || 0,
        includeAllMedia: includeAllMedia
      }
      if (q !== null && q !== undefined && q !== '') {
        params.q = isTrue(state.filters.exact) && (!q.startsWith('"')) ? `"${q}"` : q
      }
      if (searchableType) {
        params.searchable_type = searchableType
      }
      const { data } = await http.get('search', { params })
      result = data.items
    } catch (err) {
      // na
    }
    return result
  },
  async searchWithFacets (context, { q = '', exact, limit, offset, searchableType, includeAllMedia, callType = null }) {
    let result = []
    try {
      const params = {
        callType: callType,
        limit: limit || 0,
        offset: offset || 0,
        includeAllMedia: includeAllMedia,
        highlightSearchResults: true
      }
      if (q !== null && q !== undefined && q !== '') {
        params.q = (exact && (!q.startsWith('"'))) ? `"${q}"` : q
      }
      if (searchableType) {
        params.searchable_type = searchableType
      }
      const { data } = await http.get('search', { params })
      result = data
    } catch (err) {
      // na
    }
    return result
  },
  async searchSuggestionsWithReturn (context, { field, q, content, searchableType }) {
    try {
      const params = {
        q
      }
      if (content) {
        params[`fk_${content.type}_id`] = content.id
      }
      if (searchableType) {
        params.searchable_type = searchableType
      }

      const { data } = await http.get(`search/suggest/${field}`, { params })
      return data
    } catch (err) {
      return []
    }
  },
  async setSearchPage ({ commit, dispatch }, page) {
    const route = getRoute()
    const { q = '', exact: exactMatch = false } = route.query
    commit(SEARCH_UPDATE_OFFSET, {
      offset: Math.max(page, 0)
    })
    await dispatch('search', { q, exactMatch })
  },
  setSearchResultsPerPage ({ commit, dispatch }, limit) {
    const route = getRoute()
    const { q = '', exact: exactMatch = false } = route.query
    commit(SEARCH_UPDATE_LIMIT, { limit })
    dispatch('search', { q, exactMatch })
  },
  setSort ({ getters, commit, dispatch }, sort) {
    if (getters.isWidgetListView ) {
      commit(SEARCH_UPDATE_OFFSET, { offset: 0 })
    }
    const route = getRoute()
    const { q = '', exact: exactMatch = false } = route.query

    let sortWithLocale = ''
    if (sort.locale && sort.value) {
      sortWithLocale = `${sort.value}_sort_${sort.locale}`
    } else if (sort.noModifier) {
      sortWithLocale = sort.value
    } else if (sort.value) {
      sortWithLocale = `${sort.value}_sort`
    }

    commit(SEARCH_UPDATE_SORT, { sort: sortWithLocale, direction: sort.direction })
    dispatch('search', { q, exactMatch })
  },
  setMode ({ commit }, key) {
    commit(SET_VIEW_MODE_KEY, { key })
  },
  setDefaultSort ({ commit }, defaultSortMode) {
    let sort = ''
    let direction = ''
    switch (defaultSortMode) {
      case 'name_asc':
        sort = `name_sort_${i18n.global.locale.replace('-', '_')}`
        direction = 'asc'
        break
      case 'name_desc':
        sort = `name_sort_${i18n.global.locale.replace('-', '_')}`
        direction = 'desc'
        break
      case 'identifier_asc':
        sort = 'identifier_sort'
        direction = 'asc'
        break
      case 'identifier_desc':
        sort = 'identifier_sort'
        direction = 'desc'
        break
      case 'description_asc':
        sort = `description_${i18n.global.locale.replace('-', '_')}`
        direction = 'asc'
        break
      case 'description_desc':
        sort = `description_${i18n.global.locale.replace('-', '_')}`
        direction = 'desc'
        break
      case 'searchable_type_asc':
        sort = 'searchable_type'
        direction = 'asc'
        break
      case 'searchable_type_desc':
        sort = 'searchable_type'
        direction = 'desc'
        break
      case 'updated_asc':
        sort = 'updated'
        direction = 'asc'
        break
      case 'updated_desc':
        sort = 'updated'
        direction = 'desc'
        break
      default:
        sort = ''
        direction = ''
    }
    commit(SEARCH_UPDATE_SORT, { sort, direction })
  },
  navigateToSearch ({ state, commit, rootGetters }, query) {
    const route = getRoute()

    if (rootGetters["widgets/isWidget"]) {
      if (state.keepOffset === true && !route.query.keepOffset) {
        commit(SET_KEEP_OFFSET, false)
      } else if (!route.query.keepOffset) {
        commit(SEARCH_UPDATE_OFFSET, { offset: 0 })
      }
    } else {
      if (state.keepOffset === true) {
        commit(SET_KEEP_OFFSET, false)
      } else {
        commit(SEARCH_UPDATE_OFFSET, { offset: 0 })
      }
    }

    if (query) {
      const q = query.input !== '' ? query.input : undefined
      const exact = typeof query.exact !== 'undefined' ? query.exact : state.filters.exact

      if (q !== route.query.q || !q) {
        if (!state.filters.selected.part_number) {
          commit(SEARCH_FILTERS_SET_SELECTED, { selected: [] })
        }
      }
      let queryParams
      if (query.searchable_type) {
        queryParams = {
          q,
          exact,
          searchable_type: query.searchable_type
        }
      } else queryParams = {
        q,
        exact
      }
      router.push({
        name: 'Search',
        query: queryParams
      }).catch(() => {})
    } else {
      const queryParams = Object.assign({}, state.filters.selected)
      queryParams.q = state.filters.query || undefined
      queryParams.exact = state.filters.exact
      router
        .push({
          name: 'Search',
          query: queryParams
        })
        .catch(() => {})
    }
  },
  resetOffset({ commit }) {
    commit(SEARCH_UPDATE_OFFSET, { offset: 0 })
  },
  clearSearchTrackingId({ commit }) {
    commit(CLEAR_SEARCH_TRACKING_ID)
  },
  setSearchTrackingId({ commit, rootGetters, dispatch }, id ) {
    if (!id) return

    if (!!rootGetters['browse/getSearchTrackingId']) {
      dispatch('browse/clearSearchTrackingId', null, { root: true })
    }
    commit(SET_SEARCH_TRACKING_ID, id)
  },
  keepPagination({ commit, state }) {
    if (state.offset >= 1) {
      commit(SET_KEEP_OFFSET, true)
    }
  },
  setSuggestionQuery({commit, dispatch}, query) {
    commit(SET_SUGGESTION_QUERY, query)
  }
}

const mutations = {
  [SET_PREVIOUS_SEARCH_REQUEST] (state, { q = '', exactMatch = 'false', callType = null }) {
    state.previousSearchRequest = { q, exactMatch, callType }
  },
  [SET_SEARCH_TRACKING_ID] (state, id) {
    state.searchTrackingId = id
  },
  [SEARCH] (state, { items, facets, meta, selected }) {
    state.items = items
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0

    for (let i = 0; i < state.filters.items.length; i++) { // eslint-disable-line
      const filter = state.filters.items[i]

      if (filter.type === 'facet' ||
        filter.type === 'list' ||
        filter.type === 'category' ||
        filter.type === 'type' ||
        filter.type === 'mapped') {
        const values = facets[filter.field] || []
        const selectedValue = selected[filter.field]

        if (!values.length && selectedValue) {
          values.push(...selectedValue.map((value) => { //eslint-disable-line
            return {
              key: value,
              count: 0
            }
          }))
        }
        // Vue.set(filter, 'values', values)   --  deprecated
        filter['values'] = values
      }
    }
    state.isLoaded = true
    state.isSearching = false
  },
  [SEARCH_HISTORY_UPDATE] (state, searchHistory) {
    state.searchHistory = searchHistory
    state.searchHistoryLoaded = true
  },
  [SEARCH_UPDATE_OFFSET] (state, { offset }) {
    state.offset = offset
  },
  [SEARCH_UPDATE_LIMIT] (state, { limit }) {
    state.limit = limit
    state.offset = 0
  },
  [SEARCH_UPDATE_SORT] (state, { sort, direction }) {
    state.sort = sort
    state.direction = direction
  },
  [SEARCH_LOADING] (state) {
    state.isLoaded = false
    state.isSearching = true
  },
  [SET_VIEW_MODE_KEY] (state, { key }) {
    state.viewModeKey = key
    localStorage.setItem('viewModeKey', key)
  },
  [CLEAR_SEARCH_TRACKING_ID] (state) {
    state.searchTrackingId = null
  },
  [SET_KEEP_OFFSET] (state, value) {
    state.keepOffset = value
  },
  [SET_SUGGESTION_QUERY] (state, value) {
    state.suggestionQuery = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    filters
  }
}
