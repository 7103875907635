<template>
  <header class="has-background-white widget-header p-1">
    <div class="mobile-home-logo">
      <widget-home-logo class="endpoints"/>
      <widget-header-home class="mobile-home-button"/>
    </div>
    <div class="desktop-home-logo">
      <widget-home-logo class="endpoints"/>
    </div>
    <widget-global-search-bar
      :input="input"
      :isExactMatch="isExactMatch"
      @updateInput="updateInput"
      @updateExactMatch="updateExactMatch"
    />
    <widget-header-home class="desktop-home-button"/>
  </header>
</template>

<script setup>
import WidgetGlobalSearchBar from './WidgetGlobalSearchBar'
import WidgetHomeLogo from './WidgetHomeLogo'
import WidgetHeaderHome from './WidgetHeaderHome'
import { useSearch } from "@/components/widgets/composables/useSearch";
const emit = defineEmits(['updateExactMatch', 'updateInput'])
const props = defineProps({
    input: {
      type: String,
      required: true
    },
    isExactMatch: {
      type: String,
      required: false
    }
  })

  const updateInput = (value) => {
    emit('updateInput', value)
  }

  const updateExactMatch = (value) => {
    emit('updateExactMatch', value)
  }

</script>

<style lang="scss" scoped>
  .endpoints {
    min-width: 5rem;
    min-height: 2rem;
    float: left;
  }
  .widget-header {
    @media screen and (min-width: 641px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media screen and (max-width: 640px) {
      margin-bottom: 1em;
    }
  }
  header {
    position: sticky;
    top: 0;
    z-index: 40;
  }
  .mobile-home-logo {
    @media screen and (min-width: 639px) {
      display: none;
    }
    display: inline-block;
  }
  .desktop-home-logo {
    @media screen and (max-width: 640px) {
      display: none;
    }
  }
  .mobile-home-button {
    position: absolute;
    right: 0px
  }
  .desktop-home-button {
    @media screen and (max-width: 640px) {
      display: none;
    }
    float: right;
  }
</style>
