<template>
  <div>
    <div class="details-wrapper">
      <div class="card top-card">
        <info-wrapper :showTags="false"/>
      </div>
      <div  v-if="content"
            class="card p-1"
            :class="{'top-card': !isMobileView }">
        <info-tags :id="castIdToNumber(content.id)"
                   :type="content.type"
                   :style="infoTagsStyle"/>
      </div>
    </div>
    <div  v-if="hasAnyTabMetadata"
          id="about-wrapper"
          class="about-wrapper card m-4">
      <div class='container tabbed-accordian'>
        <div class="columns is-centered">
          <div class="column">
            <div class="is-centered"
                 :class="{'tabs':  width > 768, 'accordian': width <= 768}">
              <ul>
                <li  v-if="canViewComments"
                     :class="{'is-active': tabStates.comments}"
                     id="comment"
                     @click="selectTab('comments')">
                  <a  class="tab-link">
                    <div>
                      <pills-tag  :text="$tc('comment', 2)"
                                  :total="content.comments.total"/>
                    </div>
                    <span class="drawer-icons">
                      <i  v-if="tabStates.comments && isMobileView"
                          class="fa fa-minus" />
                      <i v-else-if="isMobileView" class="fa fa-plus"/>
                    </span>
                  </a>
                </li>
                <transition  v-if="canViewComments"
                             name="slide" mode="in-out"
                             enter-from-class="slide-up-enter"
                             leave-from-class=""
                             leave-to-class="">
                  <div v-if="tabStates.comments"
                       class="library-tab-content">
                    <comments></comments>
                  </div>
                </transition>
                <li   v-if="canViewRelated"
                      :class="{'is-active': tabStates.related}"
                      id="related"
                      @click="selectTab('related')">
                  <a  class="tab-link">
                    <div>
                      <pills-tag  :text="$t('related')"
                                  :total="content.related.total"/>
                    </div>
                    <span class="drawer-icons">
                      <i  v-if="tabStates.related && isMobileView"
                          class="fa fa-minus" />
                      <i v-else-if="isMobileView" class="fa fa-plus"/>
                    </span>
                  </a>
                </li>
                <transition  v-if="canViewRelated"
                             name="slide" mode="in-out"
                             enter-from-class="slide-up-enter"
                             leave-from-class=""
                             leave-to-class="">
                  <div v-if="tabStates.related"
                       class="library-tab-content">
                    <related></related>
                  </div>
                </transition>
                <li v-if="canViewWhereUsed"
                    :class="{'is-active': tabStates.whereused}"
                    id="WhereUsed"
                    @click="selectTab('whereused')">
                  <a  class="tab-link">
                    <div>
                      <pills-tag  :text="$t('whereUsed')"
                                  :total="content.whereused.total"/>
                    </div>
                    <span class="drawer-icons">
                      <i  v-if="tabStates.whereused && isMobileView"
                          class="fa fa-minus" />
                      <i v-else-if="isMobileView" class="fa fa-plus"/>
                    </span>
                  </a>
                </li>
                <transition name="slide" mode="in-out"
                            enter-from-class="slide-up-enter"
                            leave-from-class=""
                            leave-to-class="">
                  <div v-if="tabStates.whereused"
                       class="library-tab-content">
                    <where-used :height="'25em'"
                                :open-links-in-new-tab="null"
                                :show-title="false">
                    </where-used>
                  </div>
                </transition>
                <li v-if="canViewSuggestions"
                    :class="{'is-active': tabStates.suggestions}"
                    id="suggestions"
                    @click="selectTab('suggestions')">
                  <a  class="tab-link">
                    <div>
                      <pills-tag  :text="$tc('suggestion', 2)"
                                  :total="content.suggestions.total"/>
                    </div>
                    <span class="drawer-icons">
                      <i  v-if="tabStates.suggestions && isMobileView"
                          class="fa fa-minus" />
                      <i v-else-if="isMobileView" class="fa fa-plus"/>
                    </span>
                  </a>
                </li>
                <transition v-if="canViewSuggestions"
                            name="slide" mode="in-out"
                            enter-from-class="slide-up-enter"
                            leave-from-class=""
                            leave-to-class="">
                  <div v-if="tabStates.suggestions"
                       class="library-tab-content">
                    <suggestions></suggestions>
                  </div>
                </transition>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script setup>
import InfoWrapper from "@/components/library/content/info/InfoWrapper.vue"
import InfoTags from "@/components/library/content/info/tags/InfoTags.vue"
import Comments from "@/components/library/content/comments/Comments.vue"
import Suggestions from "@/components/library/content/suggestions/Suggestions.vue"
import WhereUsed from'../whereused/WhereUsed.vue'
import Related from "@/components/library/content/related/Related.vue"
import PillsTag from "@/components/common/PillsTag.vue"
import { onMounted, computed, reactive, toRaw, watch } from "vue"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useBreakpoints } from "@/helpers"
import { EntityType } from "@/interfaces/global"

const { width } = useBreakpoints()
const store = useStore()
const route = useRoute()

const content = computed(() => store.state['content'])
const isPart = computed(() => content.value.type === EntityType.part)
const isPage = computed(() => content.value.type === EntityType.page)

const canViewComments =  computed(() => store.getters['user/canViewComments'])
const canViewRelated = computed(() => store.getters['user/canViewRelated'])
const canViewWhereUsed = computed(() => isPart.value || isPage.value)
const canViewSuggestions = computed(() => {
  return store.getters['user/hasPartOrderSuggestions'] && isPart.value
})
const isMobileView = computed(() => width.value <= 768)
const hasAnyTabMetadata = computed(() => {
  return canViewComments.value || canViewRelated.value
    || canViewWhereUsed.value || canViewSuggestions.value
})
const infoTagsStyle = computed(() => {
  return isMobileView.value ? ""
    : "max-height: 100%; display: flex; flex-direction: column;"
})

const tabStates = reactive({
  comments: false,
  whereused: false,
  suggestions: false,
  related: false
})

watch(isMobileView, val => {
  if (val !== false) return

  let hasActiveField = false

  for (const field in tabStates) {
    if (hasActiveField) {
      tabStates[field] = false
    }
    else if (tabStates[field] === true) {
      hasActiveField = true
    }
  }

  if (hasActiveField === false) {
    selectFirstTab()
  }
})

function castIdToNumber (id) {
  return parseInt(id)
}

function selectTab (tab) {
  if (isMobileView.value) {
    tabStates[tab] = !tabStates[tab]
  } else {
    for (const property in tabStates) {
      tabStates[property] = (property.toString() === tab)
    }
  }
}

function selectFirstTab() {
  if (canViewComments.value) {
    tabStates.comments = true
  } else if (canViewRelated.value) {
    tabStates.related = true
  } else if (canViewWhereUsed.value) {
    tabStates.whereused = true
  } else if (canViewSuggestions) {
    tabStates.suggestions = true
  }
}

async function setTabStates (isLoading) {
  if(route.fullPath.includes('tab=related')) {
    tabStates['related'] = true
    document.getElementById("related")?.scrollIntoView()
    return
  } else {
    for (const property in tabStates) {
      if ((toRaw(content.value))[property].total) {
        tabStates[property] = true
        document.getElementById("property")?.scrollIntoView()
        return
      }
    }
    // set default if it gets to here
    if (!isMobileView.value) {
      selectFirstTab()
    }
  }
}

onMounted(async () => {
  await setTabStates(true)
})

</script>

<style scoped lang="scss">
.details-wrapper {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-top: 1em;
  }
  > .card {
    margin: 0;
    width: 100%;
  }
  div:last-child {
    flex-grow: 1
  }
}
.card:hover {
  border: none;
}
.columns {
  overflow: unset;
}
.tab-link {
  color: black;
}
.top-card {
  height: fit-content;
}
.about-wrapper {
  .container {
    min-height: 10em;
  }
}
</style>
