<template>
  <search-options class="navbar-item is-marginless search-nav"
                  :placeholder="$t('searchPlaceholder')"
                  :value="input"
                  @enter="search"
                  @searchContent="searchContentType"
                  @updateInputWithoutSearch="updateInput"
  />
</template>

<script setup>
  import { useSearch } from '@/components/widgets/composables/useSearch'
  import SearchOptions from '@/components/common/search/SearchOptions'

  import { useStore } from "vuex";
  import { computed } from "vue";
  const store = useStore()
  const emit = defineEmits(['updateExactMatch', 'updateInput'])
  const props = defineProps({
    input: {
      type: String,
      required: true
    },
    isExactMatch: {
      type: String,
      required: false
    }
  })

  const {
    navigateToSearch,
  } = useSearch()

  const updateInput = (value) => {
    emit('updateInput', value)
  }

  const searchContentType = (value) => {
    navigateToSearch(
      props.input,
      { clearAllContent: true },
      value
    )
  }

  const search = (value) => {
    updateInput(value)
    navigateToSearch(
      value,
      { clearAllContent: true }
    )
  }

  const updateExactMatch = (value) => {
    emit('updateExactMatch', value)
  }

</script>

<style lang="scss" scoped>
.search-nav {
  min-width: 50%;
  @media screen and (max-width: 640px) {
    padding-bottom: 1rem;
  }
}
</style>
