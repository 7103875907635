<template>
  <div class="search-history-box">
    <div>
      <div class="title-five search-history-header">
        <h3 class="header-text-color">{{ $t('recentSearches') }}</h3>
      </div>
      <div>
        <ul>
          <li v-for="item in searchHistory" class="search-history-item">
             <a @click="select(item)">
               {{ item }}
             </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="hasRecentlyViewed" class="section-margin">
      <div class="title-five search-history-header">
        <h3 class="header-text-color">{{ $t('recentlyViewed') }}</h3>
      </div>
      <div>
        <div v-for="contentItem in recentlyViewed" class="is-row content-pointer" @click="selectContent(contentItem)">
          <div class="image is-32x32">
            <thumbnail
              :thumbnail-url="getThumbnailUrl(contentItem)"
              :thumbnail-type="contentItem.entityType"
              class="is-square"
            />
          </div>
          <div class="search-history-item">
            <a>{{ contentItem.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hideSearchButton" class="search-button">
      <button class="button" @click="enter">
        <span class="search-button-text">{{ $t('viewAll') }}</span>
        <span class="search-button-arrow fas fa-angle-right"/>
      </button>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { onMounted, computed } from "vue";
  import Thumbnail from '@/components/common/Thumbnail';

  const emit = defineEmits( ['enter','selectSuggestion','close'])
  const isWidget = computed(() => store.getters['widgets/isWidget'])

  const store = useStore()

  onMounted( async ()  =>  {
    await store.dispatch('recentlyviewed/getRecentlyViewed')
  })

  //computed properties
  const searchHistory = computed(() => store.getters['search/getSearchHistory'])
  const recentlyViewed = computed(() => store.getters['recentlyviewed/getRecentlyViewedItems'].slice(0,5))
  const hasRecentlyViewed = computed(() => !isWidget.value && recentlyViewed.value?.length > 0)
  const hideSearchButton = computed(() => store.state.user.searchHidden)
  //methods
  const select = (value) => {
    emit('selectSuggestion', value)
  }
  const enter = () => {
    emit('enter')
  }
  const getThumbnailUrl = (contentItem) => {
    return contentItem?.thumbnailUrl ?? `https://static.digabit.com/${contentItem?.entityType?.toLowerCase()}.svg`
  }
  const selectContent = async (content) => {
    emit('close')
    await store.dispatch('content/navigateToContent', { content })
  }
</script>

<style scoped>
.search-history-box {
  text-align: left;
  display: block;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  border: 1px solid lightgrey;
  z-index: auto;
}
.search-history-header {
  background-color: #fafafa;
  padding: .25em;
}
.header-text-color {
  color: black;
}
.search-history-item {
  margin-top: .20em;
  margin-left: .25em;
  font-weight: bold;
}
.is-row {
  display: flex;
}

.search-button {
  text-align: center;
  position: absolute;
  bottom: .5em;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.search-button-text {
  font-weight: bold;
}

.search-button-arrow {
  margin-left: .5em;
}
.content-pointer {
  cursor: pointer;
}
.section-margin {
  margin-top: 1em;
}

</style>
