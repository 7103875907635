<template>
  <div v-show="showSpinner"
       class="spinner">
    <h3 class="justify-center">
      {{ $t('largePDFDownloadWarning') }}
    </h3>
    <utility-block></utility-block>
  </div>
  <div v-show="!showSpinner"
       id="pspdfkit-container"
       class="pdf-container"
       @fullscreenchange="setupFullScreenToolbar" />
</template>

<script>
import { mapState } from 'vuex'
import PSPDFKit from 'pspdfkit'
import UtilityBlock from "@/components/common/UtilityBlock.vue";

/**
 * Former PSPDF Web implementation
 */
export default {
  name: 'PspdfKitContainer',
  components: { UtilityBlock },
  props: {
    licenseKey: {
      type: String,
      required: true
    },
    pdfUrl: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      default: ''
    },
    fileSize: Number
  },
  data () {
    return {
      pspdfkitInstance: null, // For updating between normal view and full-screen
      retryCount: 0,
      showSpinner: false
    }
  },
  computed: {
    ...mapState({
      book: (state) => state.content.toc.book,
      content: (state) => state.content
    }),
    hasDisabledPrinting () {
      return this.$store.getters['user/hasDisablePrinting'] || (this.content?.disableExport) || (this.book?.disableExport)
    },
    hasLargePDFWarningEnabled () {
      return this.$store.getters["user/enableLargePdfDownloadWarning"]
    },
    shortLang () {
      return this.$i18n.locale.split('-')[0]
    }
  },
  methods: {
    getFullScreenToolbarItems () {
      return [
        { type: 'spacer' },
        { type: 'zoom-out' },
        { type: 'zoom-in' },
        {
          type: 'custom',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M200 288H88c-21.4 0-32.1 25.8-17 41l32.9 31-99.2 99.3c-6.2 6.2-6.2 16.4 0 22.6l25.4 25.4c6.2 6.2 16.4 6.2 22.6 0L152 408l31.1 33c15.1 15.1 40.9 4.4 40.9-17V312c0-13.3-10.7-24-24-24zm112-64h112c21.4 0 32.1-25.9 17-41l-33-31 99.3-99.3c6.2-6.2 6.2-16.4 0-22.6L481.9 4.7c-6.2-6.2-16.4-6.2-22.6 0L360 104l-31.1-33C313.8 55.9 288 66.6 288 88v112c0 13.3 10.7 24 24 24zm96 136l33-31.1c15.1-15.1 4.4-40.9-17-40.9H312c-13.3 0-24 10.7-24 24v112c0 21.4 25.9 32.1 41 17l31-32.9 99.3 99.3c6.2 6.2 16.4 6.2 22.6 0l25.4-25.4c6.2-6.2 6.2-16.4 0-22.6L408 360zM183 71.1L152 104 52.7 4.7c-6.2-6.2-16.4-6.2-22.6 0L4.7 30.1c-6.2 6.2-6.2 16.4 0 22.6L104 152l-33 31.1C55.9 198.2 66.6 224 88 224h112c13.3 0 24-10.7 24-24V88c0-21.3-25.9-32-41-16.9z"/></svg>',
          onPress: () => {
            // Common path
            if (document.fullscreenElement && document.exitFullscreen) {
              document.exitFullscreen();
            }
          }
        },
        { type: 'spacer' }
      ]
    },
    getNormalToolbarItems () {
      const toolbarItems = [
        {type: 'sidebar-thumbnails'},
        {type: 'sidebar-document-outline'},
        {type: 'pager'},
        {type: 'pan'},
        {type: 'zoom-out'},
        {type: 'zoom-in'},
        {type: 'zoom-mode'},
        {
          type: 'custom',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 344v112a23.9 23.9 0 0 1 -24 24H312c-21.4 0-32.1-25.9-17-41l36.2-36.2L224 295.6 116.8 402.9 153 439c15.1 15.1 4.4 41-17 41H24a23.9 23.9 0 0 1 -24-24V344c0-21.4 25.9-32.1 41-17l36.2 36.2L184.5 256 77.2 148.7 41 185c-15.1 15.1-41 4.4-41-17V56a23.9 23.9 0 0 1 24-24h112c21.4 0 32.1 25.9 17 41l-36.2 36.2L224 216.4l107.2-107.3L295 73c-15.1-15.1-4.4-41 17-41h112a23.9 23.9 0 0 1 24 24v112c0 21.4-25.9 32.1-41 17l-36.2-36.2L263.5 256l107.3 107.3L407 327.1c15.1-15.2 41-4.5 41 16.9z"/></svg>',
          onPress: () => {
            const element = document.getElementById('pspdfkit-container')
            // Common path
            if (element.requestFullscreen) {
              element.requestFullscreen()
            }
          }
        },
        {type: 'spacer'},
        {type: 'search'}
      ]

      if (this.hasDisabledPrinting) {
        toolbarItems.push({type: 'debug'})
      } else {
        toolbarItems.push({type: 'print'})
        toolbarItems.push({type: 'debug'})
      }

      return toolbarItems
    },
    /**
     * Setups a normal toolbar in addition to any other operations upon first load of page
     */
    async loadPSPDFKit() {
      try {
        await this.setupNormalToolbar()

        if (!!this.query && this.query.length > 0) {
          const trimmedQuery = this.query.trim().replace(
              /^"(.*)"$/g,
              '$1'
          )

          await this.pspdfkitInstance.startUISearch(trimmedQuery)
        }

        this.$parent.errorMsg = ''
      } catch (err) {
        // Retry loading PSPDFKit every 5 ms. If
        // we still fail after 1000 times then
        // just show the error we are receiving.
        this.retryCount += 1
        if (this.retryCount < 1000) {
          await this.$wait(5)
          this.loadPSPDFKit()
        } else {
          this.$parent.errorMsg = err.message
        }
      }
    },
    async setupFullScreenToolbar() {
      if (document.fullscreenElement) {
        this.pspdfkitInstance.setToolbarItems(this.getFullScreenToolbarItems)
      } else {
        await this.setupNormalToolbar()
      }
    },
    async setupNormalToolbar() {
      this.pspdfkitInstance.addEventListener('annotations.press', (e) => {
        if (e.annotation && e.annotation.action && e.annotation.action.script) {
          const script = e.annotation.action.script
          console.error('Received an annotation pressed event from PSPDF that contains a script. ' +
            'We do not support script events in Documoto.\n' +
            script)
          e.preventDefault()
        }
      })

      const toolbarItems = this.getNormalToolbarItems()
      this.pspdfkitInstance.setToolbarItems(toolbarItems)
    }
  },
  /**
   * Clean up when the component is unmounted;
   * then, it is ready to load another document.
   */
  beforeUnmount() {
    PSPDFKit.unload(".pdf-container")
  },
  async mounted() {
    if(this.fileSize && this.hasLargePDFWarningEnabled && this.fileSize > 52428800) {
      this.showSpinner = true
    }
    /**
     * Build instance once upon mount and modify it as needed
     * (e.g. from/to normal-view to/from full-screen)
     */
    this.pspdfkitInstance = await PSPDFKit.load({
      container: "#pspdfkit-container",
      document: this.pdfUrl,
      licenseKey: this.licenseKey,
      locale: this.shortLang,
      styleSheets: ['/api/styles/user.css'],
      toolbarItems: []
    })
    await this.loadPSPDFKit()
    if(this.showSpinner) this.showSpinner = false
  },
}
</script>


<style scoped lang="scss">
.pdf-container {
  height: 100%;
}
.spinner {
  height: 100%;

  h3 {
    padding-top: 20%;
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
